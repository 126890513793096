<template>
  <v-container fluid>
    <PageNavigation :items="items" />

    <v-row>
      <v-col cols="12">
        <base-material-card title="Stock List" icon="mdi-apple-finder">
          <v-sheet>
            <v-toolbar-items class="d-flex align-center">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.search')"
                v-model="search"
                append-icon="mdi-magnify"
              />

              <v-spacer />

              <v-btn
                color="green"
                elevation="2"
                @click="handleDialog"
                v-if="permissionCheck('add')"
              >
                <v-icon left>mdi-account-plus</v-icon>
                {{ $vuetify.lang.t("$vuetify.add") }}
              </v-btn>
            </v-toolbar-items>
          </v-sheet>

          <GeneralTable
            :head="headers"
            :data="datas"
            :search="search"
            :tableLoading.sync="tableLoading"
            v-on:edititem="handleEditDialog"
            v-on:deleteitem="handleDeleteDialog"
          />
        </base-material-card>

        <StockInCRUD
          :dialog.sync="dialog"
          :myObj="obj"
          @handleData="handleStockIn"
        />

        <DialogDelete
          :dialogDelete.sync="dialogDelete"
          :deleteObj="deleteObj"
          @handleDelete="handleDeleteConfirm"
          v-on:edititem="handleEditDialog"
          v-on:deleteitem="handleDeleteDialog"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { warehouseList, warehouseUpsert, warehouseDel } from "@/api/purchase";
import { sellList } from "@/api/basicdata";

const clone = (obj) => Object.assign({}, obj);
const renameKey = (object, key, newKey) => {
  const clonedObj = clone(object);
  const targetKey = clonedObj[key];

  delete clonedObj[key];
  clonedObj[newKey] = targetKey;
  return clonedObj;
};

const newObj = () => {
  return {
    id: "",
    name: "",
    code: "",
    leader: "",
    leader_info: {},
    address: "",
    contact: "",
    mobile: "",
    note: "",
    sell_company: [],
  };
};

const newSearchForm = () => {
  return {
    skip: 0,
    limit: 10,
    key: "",
  };
};

export default {
  name: "StockIn",
  components: {
    GeneralTable: () => import("@/components/tableImg"),
    StockInCRUD: () => import("@/components/purchase/stockInCRUD"),
    DialogDelete: () => import("@/components/deleteDialog"),
    PageNavigation: () => import("@/components/pageNavigation"),
  },
  data() {
    return {
      tableLoading: true,
      dialog: false,
      dialogDelete: false,
      dialogStockIn: false,
      search: "",
      obj: newObj(),
      deleteObj: newObj(),
      searchForm: newSearchForm(),
      datas: [],
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.purchase") },
        { text: "Stock List" },
      ],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
          width: "100px",
          sortable: false,
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.warehouseId"),
          value: "code",
          width: "130px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.warehouseName"),
          value: "name",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.contactPerson"),
          value: "leader",
          width: "150px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.mobile"),
          value: "mobile",
          width: "120px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.addr"),
          value: "address",
          width: "300px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.note"),
          value: "note",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.productList1"),
          value: "stockList",
          align: "center",
          width: "140px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.stockInOutRec"),
          value: "stockInOut",
          align: "center",
          width: "160px",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "actions",
          sortable: false,
          align: "center",
          width: "50px",
        },
      ],
    };
  },
  methods: {
    getData() {
      warehouseList(this.searchForm)
        .then((data) => {
          this.datas = [...data.items];

          for (var i = 0; i < this.datas.length; i++) {
            this.datas[i].itemNo = i + 1;
          }
          this.tableLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.tableLoading = false;
        });
    },
    handleDialog() {
      this.dialog = true;
      this.obj = new Object();
    },
    handleEditDialog(obj) {
      if (this.permissionCheck("modify")) {
        this.dialog = true;
        this.obj = obj;
      }
    },
    handleDeleteDialog(obj) {
      this.dialogDelete = true;
      this.deleteObj = obj;
    },
    handleStockIn(obj) {
      this.dialog = false;

      warehouseUpsert(obj)
        .then((response) => {
          if (response.meta === 0) {
            this.getData();
            this.$toast.error("Warehouse Upsert Successful");
          }
        })
        .catch((err) => {
          console.log("Warehouse Upsert Error", err);
          this.$toast.error("Warehouse Upsert Error");
        });
    },
    handleDeleteConfirm(obj) {
      this.dialogDelete = false;

      warehouseDel(obj.id)
        .then((response) => {
          if (response.meta === 0) {
            this.getData();
            this.$toast.success("Warehouse Delete Successful");
          }
        })
        .catch((err) => {
          console.log("Warehouse Delete Error", err);
          this.$toast.error("Warehouse Delete Error");
        });
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    dialog() {
      if (this.dialog == false) {
        this.getData();
      }
    },
  },
};
</script>